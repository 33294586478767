// Import and register all your controllers from the importmap under controllers/*
import { Application } from "@hotwired/stimulus";
import { Alert, Tabs, Dropdown } from "tailwindcss-stimulus-components";
import upload_progress from "controllers/upload_progress_controller";
import nav from "controllers/nav_controller";
import change_budget_form from "controllers/change_budget_form_controller";
import confirm_dialog from "controllers/confirm_dialog_controller";
import dialog from "controllers/dialog_controller";
import import_expense from "controllers/import_expense_controller";
import webauthn from "controllers/webauthn_controller";
import chart from "controllers/chart_controller";
import dialog2 from "../../components/dialog_component_controller"
import submit_on_change from "controllers/submit_on_change_controller"
import category_nav from "controllers/category_nav_controller"
import copy from "controllers/copy_controller"

class TypeApexCharts {
	constructor(a: Element, b: object) {
	}

	render() {
	}
}

declare global {
	interface Window {
		ApexCharts: undefined | typeof TypeApexCharts;
		getCookie: (name: string) => string;
		setCookie: (name: string, value: string, exp: number) => void;
		Stimulus: ReturnType<typeof Application.start>;
		Turbo: {
			visit(url: string): void;
			setConfirmMethod(
				fn: (text: string, el: HTMLElement) => Promise<boolean>
			): void;
		};
		toast: ({
			text,
			type,
		}: {
			text: string;
			type?: "error" | "notice" | "info";
		}) => void;
	}
}

window.Stimulus = Application.start();
window.Stimulus.debug = window.location.hostname === "localhost";

window.Stimulus.register("chart", chart);
window.Stimulus.register("category-nav", category_nav);
window.Stimulus.register("upload-progress", upload_progress);
window.Stimulus.register("alert", Alert);
window.Stimulus.register("dropdown", Dropdown);
window.Stimulus.register("tabs", Tabs);
window.Stimulus.register("nav", nav);
window.Stimulus.register("change-budget-form", change_budget_form);
window.Stimulus.register("confirm-dialog", confirm_dialog);
window.Stimulus.register("dialog", dialog);
window.Stimulus.register("dialog2", dialog2);
window.Stimulus.register("import-expense", import_expense);
window.Stimulus.register("webauthn", webauthn);
window.Stimulus.register("submit-on-change", submit_on_change);
window.Stimulus.register("copy", copy);

window.toast = ({ text, type = "notice" }) => {
	const flashTemplate = document.querySelector("template#flash_template") as
		| HTMLTemplateElement
		| undefined;
	if (flashTemplate) {
		const classes = {
			notice: "bg-green-200 text-green-600",
			error: "bg-red-100 text-red-800",
			info: "bg-blue-100 text-blue-800",
		}[type];
		const temp = document.importNode(flashTemplate.content, true);
		temp.querySelector(`[data-id=${type}]`)?.classList?.remove("hidden");
		const alertText = temp.querySelector(`[data-id=alert-text]`) as
			| HTMLElement
			| undefined;
		if (alertText) {
			alertText.innerText = text;
		}
		temp
			.querySelector(`[data-controller=alert]`)
			?.classList?.add(...classes.split(" "));
		document.body.appendChild(temp);
	}
};

window.getCookie = function (cname: string): string {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

window.setCookie = function (cname: string, cvalue: string, exdays: number) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};


const confirmMethod = (title: string, element: HTMLElement): Promise<boolean> => {
	return new Promise((resolve) => {
		const dialogTemplate = document.querySelector<HTMLTemplateElement>(
			"template#confirm_template"
		)!;
		const temp = document.importNode(dialogTemplate.content, true);
		const dialog = temp.querySelector("dialog")!;
		const destructiveButton = dialog.querySelector("[data-destructive=true]")!
		const defaultButton = dialog.querySelector("[data-default=true]")!
		if (element.dataset.style === "destructive") {
			defaultButton.remove()
		} else {
			destructiveButton.remove()
		}

		dialog.dataset.confirmDialogTitleValue = title || "Confirm";
		dialog.dataset.confirmDialogBodyValue =
			element.dataset.body || "Are you sure?";

		dialog.dataset.confirmDialogButtonValue = element.dataset.button || "OK";


		dialog.addEventListener("close", () => {
			resolve(dialog?.returnValue === "confirm");
		});
		document.body.appendChild(temp);
	});
};

window.Turbo.setConfirmMethod(confirmMethod);

document.addEventListener("turbo:load", function () {
	document.querySelectorAll("input[type='number']").forEach((i) => {
		const input = i as HTMLInputElement;
		if (input.value.endsWith(".0")) {
			input.value = input.value + "0";
		}
	});
});
