import { Controller } from "@hotwired/stimulus"

let scrollTop = 0;
export default class extends Controller {
  #setScrollTop = () => {
    scrollTop = document.scrollingElement.scrollTop || 0
  }

  #restoreScrollTop = () => {
    console.log("setting", scrollTop)
    if (scrollTop > 0) {
      document.scrollingElement.scrollTop = scrollTop
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-visit", this.#setScrollTop)
    document.removeEventListener("turbo:load", this.#restoreScrollTop)
  }

  connect() {
    document.addEventListener("turbo:before-visit", this.#setScrollTop)
    document.addEventListener("turbo:load", this.#restoreScrollTop)
  }
}